/**
 * @file
 *
 * this file contains custom autcomplete options component
 */
import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { matchSorter } from 'match-sorter';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export function AutocompleteOption({ inputValue, label, name, adornment = null }) {
  const matches = match(name, inputValue);
  const parts = parse(name, matches);

  const theme = useTheme();

  return (
    <div>
      <Typography component="p" noWrap>
        {adornment}
        {parts.map((part, index) => (
          <span
            key={index}
            style={{
              marginLeft: adornment ? theme.spacing(0.5) : 0,
              fontWeight: part.highlight ? 700 : 400,
            }}
          >
            {part.text}
          </span>
        ))}
      </Typography>
      {label && (
        <Typography component="p" variant="caption" color="textSecondary" gutterBottom noWrap>
          {label}
        </Typography>
      )}
    </div>
  );
}

export const autoCompleteFilterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, {
    keys: ['name', 'label'],
    baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    threshold: matchSorter.rankings.CONTAINS,
  });
