import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { BiBookmark } from 'react-icons/bi';
import { useEffectOnce } from 'react-use';

import {
  dialogDescriptionId,
  dialogTitleId,
  validationSchema,
} from 'components/CreateBookmarkDialog';
import { useCreateBookmarkMutation } from 'data/queriesAndBookmarks';
import { useTenantState } from 'data/user';
import { commonTextFieldProps } from 'mui5Theme';
import { checkBookmarkExists } from 'utils';
import { useDisclosure } from 'hooks/useDisclosure';
import { BOOKMARK_TYPE } from './constants';
import { MoveBookmarkField } from './BookmarkPanel';
import { PERMISSION_CODES } from '../../constants';
import { getDefaultCollection } from 'reportView/utils';
import { FEATURE_CODES } from 'data/billingUsage';
import DialogWrapper from 'components/DialogWrapper';

export default function BookmarkButton({
  reportViewBookmarks,
  isLoadingData,
  queryUrl,
  openCreateBookmarkDialog,
  isBookmarksQuotaExhausted,
}) {
  const tenant = useTenantState();
  const canCreateBookmark = useMemo(
    () =>
      tenant?.permissions.some(
        ({ permission_code }) => permission_code === PERMISSION_CODES.CREATE_BOOKMARK
      ),
    [tenant?.permissions]
  );

  const bookmarkExists = useMemo(
    () => checkBookmarkExists(reportViewBookmarks, queryUrl),
    [queryUrl, reportViewBookmarks]
  );

  const isBookmarkBtnDisabled =
    isLoadingData || bookmarkExists || !canCreateBookmark || isBookmarksQuotaExhausted;

  const getTooltipTitle = useCallback(() => {
    if (bookmarkExists) return 'Bookmark already exists';
    if (!canCreateBookmark) return 'You do not have permission to create a bookmark';
    if (isBookmarksQuotaExhausted)
      return 'You have reached the maximum bookmarks limit. Contact your administrator to extend limit';
    return 'Add to Bookmarks';
  }, [bookmarkExists, canCreateBookmark, isBookmarksQuotaExhausted]);

  return (
    <>
      <Tooltip title={getTooltipTitle()}>
        <span>
          <IconButton
            disabled={isBookmarkBtnDisabled}
            size="small"
            onClick={() => openCreateBookmarkDialog(queryUrl)}
          >
            <BiBookmark fontSize="18px" />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}

export function useCreateBookmark() {
  const [bookmarkUrl, setBookmarkUrl] = useState('');

  const { isOpen, open, close } = useDisclosure();

  const handleOpen = useCallback(
    (url) => {
      open();
      setBookmarkUrl(url);
    },
    [open]
  );

  return { bookmarkUrl, isOpen, open: handleOpen, close };
}

export function BookmarkCreationDialog({
  isDialogOpen,
  closeDialog,
  bookmarkUrl,
  reportViewBookmarks,
  refetchAllBookmarksInConnection,
  areBookmarksLoading,
  billingInfo,
}) {
  const {
    close: closeBookmarksLimitDialog,
    isOpen: isBookmarksLimitDialogOpen,
    open: openBookmarksLimitDialog,
  } = useDisclosure();

  const params = useParams();
  const tenant = useTenantState();

  const [isFeatureQuotaExhausted, setIsFeatureQuotaExhausted] = useState(false);

  const { createBookmarkMutation } = useCreateBookmarkMutation();

  const { id: defaultCollectionId } = getDefaultCollection(reportViewBookmarks);

  const bookmarkCreateCountData = useMemo(() => {
    return billingInfo.features.find(
      (feature) => feature.feature_code === FEATURE_CODES.BOOKMARK_CREATE_COUNT
    );
  }, [billingInfo.features]);

  const bookmarkCreateCountLimit = bookmarkCreateCountData?.reference_count;

  useEffectOnce(() => {
    // On opening bookmark creation dialog, we check if the connection has hit
    // the bookmarks limit.

    async function getAllBookmarksData() {
      const allBookmarksData = await refetchAllBookmarksInConnection();
      const bookmarksCount = allBookmarksData.data.length;

      if (bookmarksCount >= bookmarkCreateCountLimit) {
        setIsFeatureQuotaExhausted(true);
        openBookmarksLimitDialog();
      }
    }

    getAllBookmarksData();
  });

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { name: '', description: '', collectionId: defaultCollectionId },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await createBookmarkMutation.mutateAsync({
          formData: {
            ...data,
            type: BOOKMARK_TYPE.REPORT_VIEW,
          },
          tenant,
          connectionId: params.connectionId,
        });
        closeDialog();
      } finally {
      }
    },
    [closeDialog, createBookmarkMutation, params.connectionId, tenant]
  );

  const decodedBookmarkUrl = decodeURIComponent(bookmarkUrl);

  if (isFeatureQuotaExhausted) {
    return (
      <DialogWrapper
        isOpen={isBookmarksLimitDialogOpen}
        closeDialog={closeBookmarksLimitDialog}
        primaryBtnText="Close"
        primaryBtnAction={closeBookmarksLimitDialog}
        title="Bookmarks limit reached"
        children={`You cannot create more bookmarks in this connection. Current Limit: ${bookmarkCreateCountLimit}`}
        size="xs"
      />
    );
  }

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth="sm"
      fullWidth
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogDescriptionId}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id={dialogTitleId}>Add Bookmark</DialogTitle>
        {!areBookmarksLoading ? (
          <DialogContent id={dialogDescriptionId}>
            <TextField
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              label="Bookmark URL"
              name="url"
              helperText={' '}
              fullWidth
              {...commonTextFieldProps}
              inputRef={register}
              value={decodedBookmarkUrl}
            />
            <TextField
              label="Bookmark Title"
              placeholder="Favourite Query"
              name="name"
              helperText={errors.name ? errors.name.message : ' '}
              error={Boolean(errors.name)}
              fullWidth
              {...commonTextFieldProps}
              inputRef={register}
              disabled={createBookmarkMutation.isLoading}
              inputProps={{ maxLength: 255 }}
            />
            <TextField
              label="Bookmark Description"
              placeholder="This is my favorite query"
              name="description"
              helperText={errors.description ? errors.description.message : ' '}
              error={Boolean(errors.description)}
              fullWidth
              {...commonTextFieldProps}
              inputRef={register}
              disabled={createBookmarkMutation.isLoading}
              inputProps={{ maxLength: 255 }}
            />
            <MoveBookmarkField
              collectionsData={reportViewBookmarks}
              isCreateFlow={true}
              {...register('collectionId')}
              name="collectionId"
              setValue={setValue}
              defaultCollectionId={defaultCollectionId}
            />
          </DialogContent>
        ) : (
          <Stack justifyContent="center" alignItems={'center'}>
            <CircularProgress />
          </Stack>
        )}
        <DialogActions>
          <Button
            disableElevation
            variant="outlined"
            size="small"
            disabled={createBookmarkMutation.isLoading}
            onClick={closeDialog}
          >
            Close
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            disabled={!isValid || createBookmarkMutation.isLoading}
            type="submit"
            startIcon={createBookmarkMutation.isLoading ? <CircularProgress size={15} /> : null}
          >
            {createBookmarkMutation.isLoading ? 'Adding' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
