/**
 * @file
 *
 * this file contains the Export Job Dialog component
 */

import {
  Dialog,
  Stack,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  DialogActions,
  Button,
  Tooltip,
  Link,
  Checkbox,
  Box,
  Chip,
  Switch,
  Divider,
} from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { CgAsterisk, CgBolt } from 'react-icons/cg';
import { MdArrowBack, MdHelpOutline, MdSearch, MdSettings, MdVpnKey } from 'react-icons/md';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

import { commonTextFieldProps } from 'mui5Theme';
import { checkPasswordStrength } from 'reportView/utils';
import { useReportViewState } from 'state/reportView';
import { getSystemConfigurationAndPreferences, navigateToSystems } from 'utils';
import { useTenantState } from 'data/user';
import { NotFoundImage } from 'components/Illustrations/NotFound';
import { DebouncedTextField } from 'components/FormComponents/DebouncedTextField';
import PopoverMenu from './PopoverMenu';
import { useDisclosure } from 'hooks/useDisclosure';
import DialogWrapper from 'components/DialogWrapper';
import { theme } from 'theme';
// import { LOCAL_STORAGE_KEYS } from '../../constants';
// import { useLocalStorage } from 'react-use';
import { useConnectionsAndSystems } from 'data/connectionsAndSystems';

export default function CreateExportJobDialog({
  isExportJobDialogOpen,
  closeExportJobDialog,
  exportUtilities,
  connection,
  isRetriggerJobFlow = false,
}) {
  const queryClient = useQueryClient();
  const tenant = useTenantState();
  const { refetchSystems } = useConnectionsAndSystems();

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isMetadataConfigured, setIsMetadataConfigured] = useState(false);

  const [hasDialogInitialized, setHasDialogInitialized] = useState(false);

  // const [isRandomizationEnabled, setIsRandomizationEnabled] = useLocalStorage(
  //   LOCAL_STORAGE_KEYS.EXPORT_FLOW_PERSONALISATIONS.RANDOMIZATION,
  //   false
  // );
  // const [fieldCountForRandomization, setFieldCountForRandomization] = useState(0);
  const [password, setPassword] = useState(''); // local password field state

  const [selectedFieldsData, setSelectedFieldsData] = useState([]);
  const initialDataRef = useRef([]);

  const [isPasswordFieldRequired, setIsPasswordFieldRequired] = useState(false);

  useEffect(() => {
    const { fork } = useReportViewState.get();
    const selectedFieldNames = fork.visibleFields.value.map((field) => {
      const [parentName, fieldName] = field.split('.');
      if (parentName === 'baseProperties') {
        return fieldName;
      }
      return field.replace('.', '/');
    });
    const _selectedFieldsData = fork.visibleFields.options.filter((field) => {
      if (selectedFieldNames.includes(field.accessor)) {
        return field;
      }
      return selectedFieldNames.includes(field.name);
    });
    const extensibleData = _selectedFieldsData.map((field) => ({
      ...field,
      _INT_randomization: false,
      _INT_randomizationConfig: { charLength: null },
    }));

    setSelectedFieldsData(extensibleData);
    initialDataRef.current = extensibleData;
  }, []);

  // if user wants to randomize the data in selected fields, user clicks
  // on the button to change the flow to 'select fields', we use the below
  // state to control which flow is shown.
  const [isExportFlow, setIsExportFlow] = useState(true);

  const { handleCreateJob, isCreatingJob, setFilePassword, setRandomizationConfiguration } =
    exportUtilities;

  const disableExportButton = isPasswordFieldRequired && (!isPasswordValid || isCreatingJob);

  const navigateToSystemConfiguration = useCallback(() => {
    navigateToSystems(connection?.tenant_id, connection?.user_system_id);
  }, [connection?.tenant_id, connection?.user_system_id]);

  const handleCloseExportJobDialog = useCallback(() => {
    closeExportJobDialog();
    setIsPasswordValid(false);
    setHasDialogInitialized(false);
  }, [closeExportJobDialog]);

  const handleCreateExportJob = useCallback(() => {
    closeExportJobDialog();
    handleCreateJob();
    setHasDialogInitialized(false);
  }, [closeExportJobDialog, handleCreateJob]);

  useEffect(() => {
    const refreshSystemConfigState = async () => {
      const userSystems = await refetchSystems();

      // Extract the metadata, picklist and password preference
      const { metadata, allPreferences } = getSystemConfigurationAndPreferences(
        userSystems.data,
        connection?.user_system_id
      );

      const passwordPreference = allPreferences?.['encrypt-exported-file']?.is_enabled;

      setIsPasswordFieldRequired(passwordPreference);

      if (isRetriggerJobFlow) {
        setIsMetadataConfigured(true);
        setHasDialogInitialized(true);
        return;
      }
      setIsMetadataConfigured(Boolean(metadata));
      setHasDialogInitialized(true);
    };

    refreshSystemConfigState();
  }, [connection?.user_system_id, isRetriggerJobFlow, tenant, queryClient, refetchSystems]);

  if (!hasDialogInitialized) {
    return (
      <Dialog
        open={isExportJobDialogOpen}
        onClose={closeExportJobDialog}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Export Data</DialogTitle>
        <Stack alignItems="center" spacing={2} mb={8} mt={6}>
          <Typography color="textSecondary" align="center" px={6}>
            Initializing Export options
          </Typography>
          <CircularProgress size={25} />
        </Stack>
      </Dialog>
    );
  }

  if (!isMetadataConfigured) {
    return (
      <Dialog open={isExportJobDialogOpen} onClose={closeExportJobDialog} disableEscapeKeyDown>
        <DialogTitle>Export Data</DialogTitle>
        <Stack height="100%" alignItems="center" m={1}>
          <NotFoundImage width="180px" height="200px" />
          <Typography color="textSecondary" align="center" px={6}>
            System metadata configuration is required before export. Please configure the metadata
            to continue
            <Link
              sx={{ cursor: 'pointer', ml: 1 }}
              onClick={navigateToSystemConfiguration}
              fontWeight="600"
            >
              Configure Metadata
            </Link>
          </Typography>
        </Stack>
        <DialogActions>
          <Button variant="contained" disableElevation size="small" onClick={closeExportJobDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={isExportJobDialogOpen}
      onClose={closeExportJobDialog}
      disableEscapeKeyDown
      maxWidth={'sm'}
    >
      {!isExportFlow ? (
        <SelectFieldsFlow
          setIsExportFlow={setIsExportFlow}
          setRandomizationConfiguration={setRandomizationConfiguration}
          // setFieldCountForRandomization={setFieldCountForRandomization}
          selectedFieldsData={selectedFieldsData}
          setSelectedFieldsData={setSelectedFieldsData}
          initialDataRef={initialDataRef}
        />
      ) : (
        <>
          <DialogTitle
            sx={{
              pr: 0,
              pb: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Export Data{' '}
            <IconButton onClick={() => {}} disableRipple>
              <MdHelpOutline style={{ fontSize: 18, color: '#354A5F' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* Temporarily disable randomization step
            {sheetNameAccessor === SHEET_NAMING_CONVENTION.TECHNICAL && (
              <RandomizationStep
                setIsExportFlow={setIsExportFlow}
                isRandomizationEnabled={isRandomizationEnabled}
                setIsRandomizationEnabled={setIsRandomizationEnabled}
                fieldCountForRandomization={fieldCountForRandomization}
                setRandomizationConfiguration={setRandomizationConfiguration}
                setFieldCountForRandomization={setFieldCountForRandomization}
                setSelectedFieldsData={setSelectedFieldsData}
                initialDataRef={initialDataRef}
              />
            )} */}
            <PasswordConfigurationStep
              setIsPasswordValid={setIsPasswordValid}
              setFilePassword={setFilePassword}
              setPassword={setPassword}
              password={password}
              isPasswordFieldRequired={isPasswordFieldRequired}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseExportJobDialog} size="small">
              Close
            </Button>
            <Button
              disableElevation
              variant="contained"
              disabled={disableExportButton}
              onClick={handleCreateExportJob}
              startIcon={<CgBolt />}
              size="small"
            >
              Export
            </Button>
          </DialogActions>{' '}
        </>
      )}
    </Dialog>
  );
}

function PasswordConfigurationStep({
  setIsPasswordValid,
  setFilePassword,
  setPassword,
  password,
  isPasswordFieldRequired,
}) {
  const [isPasswordValidating, setIsPasswordValidating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false); // controls the error prop of text field
  const [passwordValidationResult, setPasswordValidationResult] = useState({}); // controls field color and helper text

  const toggleShowPassword = useCallback(() => setShowPassword((show) => !show), []);

  const [handlePasswordChange] = useDebouncedCallback((currentValue) => {
    const { isPasswordValid, helperText } = checkPasswordStrength(
      currentValue,
      isPasswordFieldRequired
    );
    setIsPasswordValid(isPasswordValid); //controls the disability of create job button
    setPasswordValidationResult({ isPasswordValid, helperText });
    setIsInvalidPassword(!isPasswordValid);
    if (isPasswordValid) {
      setFilePassword(currentValue);
    }
    setIsPasswordValidating(false);
  }, 1000);

  return (
    <>
      <Stack direction="column" mt={4}>
        <DialogContentText alignItems={'center'} alignContent={'center'}>
          {`Please enter a password to protect your Excel file. This password will be required to open
          the file in the future. ${isPasswordFieldRequired ? '(Required)' : '(Optional)'}`}
        </DialogContentText>
        <Stack direction={'row'} alignItems={'center'}>
          <Stack>
            <TextField
              value={password}
              {...commonTextFieldProps}
              sx={{ width: 400 }}
              size="small"
              label="enter a password"
              onChange={(event) => {
                setPassword(event.target.value);
                setIsPasswordValidating(true);
                handlePasswordChange(event.target.value);
              }}
              error={isInvalidPassword}
              type={showPassword ? 'text' : 'password'}
              color={passwordValidationResult.isPasswordValid ? 'success' : 'primary'}
              helperText={
                isInvalidPassword
                  ? passwordValidationResult.helperText
                  : 'Requires at least one uppercase, lowercase, special, and numeric character.'
              }
              InputProps={{
                endAdornment: isPasswordValidating ? (
                  <CircularProgress size={15} />
                ) : (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack ml={2} pb={2}>
            <Tooltip
              title={
                'Make sure to remember your password. If you lose or forget the password, you may not be able to access the contents of this file'
              }
            >
              <span>
                <BiInfoCircle style={{ fontSize: 18, color: '#44a6c6' }} />
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

function RandomizationStep({
  setIsExportFlow,
  isRandomizationEnabled,
  setIsRandomizationEnabled,
  fieldCountForRandomization,
  setRandomizationConfiguration,
  setFieldCountForRandomization,
  setSelectedFieldsData,
  initialDataRef,
}) {
  const {
    isOpen: isUnsavedChangesDialogOpen,
    close: closeUnsavedChangesDialog,
    open: openUnsavedChangesDialog,
  } = useDisclosure();

  const handleMasterRandomizationSwitch = useCallback(
    (eventOrBoolean) => {
      const isSwitchEnabled = eventOrBoolean.target.checked === true;
      if (isSwitchEnabled) {
        setIsRandomizationEnabled(true);
      }
      if (!isSwitchEnabled && fieldCountForRandomization > 0) {
        openUnsavedChangesDialog();
      }
      if (!isSwitchEnabled && fieldCountForRandomization === 0) {
        setIsRandomizationEnabled(false);
      }
    },
    [fieldCountForRandomization, openUnsavedChangesDialog, setIsRandomizationEnabled]
  );

  const handlePrimaryBtnAction = useCallback(() => {
    setIsRandomizationEnabled(false);
    setFieldCountForRandomization(0);
    setRandomizationConfiguration([]);
    closeUnsavedChangesDialog();
    setSelectedFieldsData(initialDataRef.current);
  }, [
    closeUnsavedChangesDialog,
    initialDataRef,
    setFieldCountForRandomization,
    setIsRandomizationEnabled,
    setRandomizationConfiguration,
    setSelectedFieldsData,
  ]);
  return (
    <Stack mt={6}>
      <DialogContentText>
        Enable Randomization in data for selected fields (optional)
      </DialogContentText>
      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction="row" alignItems={'center'}>
          <Switch
            checked={isRandomizationEnabled}
            onChange={handleMasterRandomizationSwitch}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ right: 8 }}
            size="small"
          />
          {fieldCountForRandomization > 0 && (
            <Typography sx={{ color: theme.palette.success.main }}>
              {fieldCountForRandomization} fields are selected for randomization
            </Typography>
          )}
        </Stack>
        {isRandomizationEnabled && (
          <Button
            variant={'text'}
            size="small"
            onClick={() => setIsExportFlow(false)}
            sx={{ textDecoration: 'underline' }}
          >
            {' '}
            Select Fields
          </Button>
        )}
      </Stack>
      {isUnsavedChangesDialogOpen && (
        <DialogWrapper
          isOpen={isUnsavedChangesDialogOpen}
          closeDialog={closeUnsavedChangesDialog}
          title={'Warning'}
          children={'All the configurations will be lost, do you want to continue?'}
          secondaryBtnAction={closeUnsavedChangesDialog}
          primaryBtnAction={handlePrimaryBtnAction}
          size={'xs'}
        />
      )}
    </Stack>
  );
}

function SelectFieldsFlow({
  setIsExportFlow,
  setRandomizationConfiguration,
  setFieldCountForRandomization,
  setSelectedFieldsData,
  selectedFieldsData,
  initialDataRef,
}) {
  const [randomizeAllFields, setRandomizeAllFields] = useState(false);
  const [disableActionButtons, setDisableActionButtons] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const {
    isOpen: isUnsavedChangesDialogOpen,
    close: closeUnsavedChangesDialog,
    open: openUnsavedChangesDialog,
  } = useDisclosure();

  useEffect(() => {
    const areAllFieldsRandomized = selectedFieldsData.every(
      (field) => field._INT_randomization === true
    );
    if (areAllFieldsRandomized) {
      setRandomizeAllFields(true);
      return;
    }
    setRandomizeAllFields(false);
  }, [selectedFieldsData]);

  const handleToggleSwitch = useCallback(
    (_accessor, checked) => {
      const updatedData = selectedFieldsData.map((field) =>
        field.accessor === _accessor ? { ...field, _INT_randomization: checked } : field
      );
      setSelectedFieldsData(updatedData);
      setHasUnsavedChanges(!areArraysEqual(initialDataRef.current, updatedData));
    },
    [initialDataRef, selectedFieldsData, setSelectedFieldsData]
  );

  const handleToggleAllSwitch = useCallback(
    (eventOrBoolean) => {
      const updatedData = selectedFieldsData.map((field) => ({
        ...field,
        _INT_randomization: eventOrBoolean.target.checked,
      }));
      setSelectedFieldsData(updatedData);
      setRandomizeAllFields(eventOrBoolean.target.checked);
      setHasUnsavedChanges(!areArraysEqual(initialDataRef.current, updatedData));
    },
    [initialDataRef, selectedFieldsData, setSelectedFieldsData]
  );

  const handleFieldLengthEdit = useCallback(
    (_accessor, newlength) => {
      const updatedData = selectedFieldsData.map((field) =>
        field.accessor === _accessor
          ? { ...field, _INT_randomizationConfig: { charLength: newlength } }
          : field
      );
      setSelectedFieldsData(updatedData);
      setHasUnsavedChanges(!areArraysEqual(initialDataRef.current, updatedData));
    },
    [initialDataRef, selectedFieldsData, setSelectedFieldsData]
  );

  const handleSearchChange = useCallback((searchParam) => {
    setSearchTerm(searchParam);
  }, []);

  const handleSave = useCallback(() => {
    try {
      initialDataRef.current = selectedFieldsData;

      const randomizationDataForExport = selectedFieldsData.reduce((accumulator, currentObj) => {
        if (currentObj._INT_randomization === true) {
          const wasLengthValueUnchanged = currentObj._INT_randomizationConfig.charLength === null;
          const isStringField = currentObj.type === 'Edm.String';
          accumulator.push({
            field_code: currentObj.nestingLevel === 0 ? currentObj.accessor : currentObj.name,
            config: {
              char_length: isStringField
                ? wasLengthValueUnchanged
                  ? Number(currentObj.maxLength)
                  : Number(currentObj._INT_randomizationConfig.charLength)
                : null,
            },
          });
        }
        return accumulator;
      }, []);
      setFieldCountForRandomization(randomizationDataForExport.length);

      setRandomizationConfiguration(randomizationDataForExport);
      setIsExportFlow(true);

      setHasUnsavedChanges(false);
      toast.success('Changes saved successfully');
    } catch (e) {
      toast.error('Changes were not saved');
    }
  }, [
    initialDataRef,
    selectedFieldsData,
    setFieldCountForRandomization,
    setIsExportFlow,
    setRandomizationConfiguration,
  ]);

  const handleGoBack = useCallback(() => {
    if (hasUnsavedChanges) {
      openUnsavedChangesDialog();
      return;
    }
    setHasUnsavedChanges(false);
    setIsExportFlow(true);
  }, [hasUnsavedChanges, openUnsavedChangesDialog, setIsExportFlow]);

  // Filter fields based on the search term
  const filteredFields = selectedFieldsData.filter((field) =>
    field.label.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const fieldsLength = selectedFieldsData.length;

  return (
    <>
      <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
        <Stack direction="row" sx={{ width: '1000px' }}>
          <DialogTitle sx={{ mx: 0, px: 0, display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => setIsExportFlow(true)}>
              <MdArrowBack />
            </IconButton>
            Select Fields
            <IconButton onClick={() => {}} disableRipple>
              <MdHelpOutline style={{ fontSize: 18, color: '#354A5F' }} />
            </IconButton>
          </DialogTitle>
        </Stack>
        <Stack>
          <DebouncedTextField
            size="small"
            isMUI5={true}
            InputProps={{
              startAdornment: (
                <MdSearch size={28} style={{ marginLeft: -5, marginRight: 2 }} color="lightGray" />
              ),
            }}
            placeholder="Search by field name"
            helperText="Search by field name"
            sx={{ mr: 2, width: 300 }}
            onChange={handleSearchChange}
            disabled={false}
            debounceTime={1000}
          />
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} mr={1} mb={2}>
        <Typography>Randomize all Fields</Typography>
        <Checkbox checked={randomizeAllFields} onChange={handleToggleAllSwitch} />
      </Stack>

      <Box
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          height: '100%',
          maxHeight: 500,
          overflow: 'auto',
          mx: 2,
          mb: 2,
        }}
      >
        {filteredFields.map((field, index) => (
          <EachField
            fieldData={field}
            key={field.accessor}
            fieldsLength={fieldsLength}
            index={index}
            setDisableActionButtons={setDisableActionButtons}
            handleFieldLengthEdit={handleFieldLengthEdit}
            handleToggleSwitch={handleToggleSwitch}
          />
        ))}
      </Box>
      <DialogActions>
        <Button
          variant={'outlined'}
          size="small"
          disableElevation
          onClick={handleGoBack}
          disabled={disableActionButtons}
        >
          Go Back
        </Button>
        <Button
          variant={'contained'}
          size="small"
          disableElevation
          disabled={disableActionButtons}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
      {isUnsavedChangesDialogOpen && (
        <DialogWrapper
          isOpen={isUnsavedChangesDialogOpen}
          closeDialog={closeUnsavedChangesDialog}
          title={'Warning'}
          children={'Unsaved changes will be lost, do you want to continue?'}
          secondaryBtnAction={closeUnsavedChangesDialog}
          primaryBtnAction={() => setIsExportFlow(true)}
          size={'xs'}
        />
      )}
    </>
  );
}

function EachField({
  fieldData,
  fieldsLength,
  index,
  setDisableActionButtons,
  handleFieldLengthEdit,
  handleToggleSwitch,
}) {
  const { name, type, maxLength, isKey, required, accessor, _INT_randomization } = fieldData;

  const [popperAnchorEle, setPopperAnchorEl] = useState(null);
  const toggleFieldConfigPopper = useCallback(
    (event) => {
      setPopperAnchorEl(popperAnchorEle ? null : event.currentTarget);
    },
    [popperAnchorEle]
  );
  const isFieldConfigPopperOpen = Boolean(popperAnchorEle);

  useEffect(() => {
    if (isFieldConfigPopperOpen) {
      setDisableActionButtons(true);
      return;
    }
    setDisableActionButtons(false);
  }, [isFieldConfigPopperOpen, setDisableActionButtons]);

  const isRequiredField = !isKey && required;

  const handleRandomizationSwitch = useCallback(() => {
    handleToggleSwitch(accessor, !_INT_randomization);
  }, [_INT_randomization, accessor, handleToggleSwitch]);

  const isStringTypeField = type === 'Edm.String';

  const dataTypeChipLabel = type.split('.')[1] + (isStringTypeField ? ` (${maxLength})` : '');
  return (
    <>
      <Stack
        direction={'row'}
        sx={{ height: 50 }}
        alignItems={'center'}
        justifyContent={'space-between'}
        mx={1}
      >
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Typography>{name}</Typography>
          <Chip
            label={dataTypeChipLabel}
            variant="outlined"
            size="small"
            sx={{ borderRadius: '4px' }}
          />

          {isKey && <MdVpnKey style={{ fontSize: 14 }} />}
          {isRequiredField && (
            <Tooltip title={'Required Field'}>
              <span>
                <CgAsterisk style={{ fontSize: 16 }} />
              </span>
            </Tooltip>
          )}
        </Stack>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Tooltip
            title={
              !isStringTypeField &&
              'We currently support character length configurations only which is not supported by this field'
            }
          >
            <span>
              <IconButton onClick={toggleFieldConfigPopper} disabled={!isStringTypeField}>
                <MdSettings style={{ fontSize: 16 }} />
              </IconButton>
            </span>
          </Tooltip>
          <Switch checked={_INT_randomization} onChange={handleRandomizationSwitch} size="small" />
        </Stack>
      </Stack>
      {fieldsLength - 1 !== index && <Divider light />}
      {isFieldConfigPopperOpen && (
        <PopoverMenu
          isOpen={isFieldConfigPopperOpen}
          anchorEl={popperAnchorEle}
          children={
            <FieldConfigurationPopper
              fieldData={fieldData}
              isRequiredField={isRequiredField}
              handleFieldLengthEdit={handleFieldLengthEdit}
              toggleFieldConfigPopper={toggleFieldConfigPopper}
            />
          }
          close={toggleFieldConfigPopper}
          zIndex={10000}
          sx={{ width: 500 }}
        />
      )}
    </>
  );
}

function FieldConfigurationPopper({
  fieldData,
  isRequiredField,
  toggleFieldConfigPopper,
  handleFieldLengthEdit,
}) {
  const { label, type, maxLength, isKey, accessor, _INT_randomizationConfig } = fieldData;
  const { charLength } = _INT_randomizationConfig;

  // const [fieldLengthValue, setFieldLengthValue] = useState(maxLength);

  const handleFieldLengthValueChange = useCallback(
    (event) => {
      handleFieldLengthEdit(accessor, event.target.value);
      //setFieldLengthValue(event.target.value);
    },
    [accessor, handleFieldLengthEdit]
  );

  const handleConfigurationChanges = useCallback(() => {
    toggleFieldConfigPopper();
  }, [toggleFieldConfigPopper]);

  return (
    <Stack>
      <Stack direction="row" alignItems={'center'} spacing={1}>
        <Typography sx={{ fontWeight: 600 }}>{label}</Typography>
        <Chip
          label={`${type.split('.')[1]} (${maxLength ?? 'N/A'})`}
          variant="outlined"
          size="small"
          sx={{ borderRadius: '4px' }}
        />
        {isKey && <MdVpnKey style={{ fontSize: 14 }} />}
        {isRequiredField && <CgAsterisk style={{ fontSize: 16 }} />}
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Stack sx={{ mt: 1 }} direction="row" justifyContent={'space-between'} alignItems="center">
        <Stack>
          <Typography sx={{ fontWeight: 600, color: '#101010' }}>Field length</Typography>
          <Typography maxWidth={300} variant="body2" sx={{ mt: 0.5 }}>
            Specifies the maximum number of characters allowed. Ensure your input does not exceed
            the length specified here to avoid errors.
          </Typography>
        </Stack>
        <Stack>
          <TextField
            value={charLength ?? maxLength}
            onChange={handleFieldLengthValueChange}
            variant="outlined"
            margin={'dense'}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{ width: 100 }}
          />
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} mt={2}>
        <Button
          variant="contained"
          onClick={handleConfigurationChanges}
          size="small"
          disableElevation
        >
          Done
        </Button>
      </Stack>
    </Stack>
  );
}

const areArraysEqual = (array1, array2) =>
  array1.every((obj, index) => JSON.stringify(obj) === JSON.stringify(array2[index]));
