/**
 * @file
 *
 * this file contains the reusable stepper dialog component
 */

import {
  DialogContentText,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Step,
  Typography,
  StepLabel,
  Stepper,
  Tooltip,
} from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import DialogWrapper from 'components/DialogWrapper';
import { getStepperSteps, setStepperDialogPrimaryBtnText } from 'reportView/utils';
import { NotFoundImage } from 'components/Illustrations/NotFound';
import GenericSuccessIllustration from 'components/Illustrations/GenericSuccessIllustration';
import { getSystemConfigurationAndPreferences, navigateToSystems } from 'utils';
import { STEPPER_FLOWS } from './constants';
import { useTenantState } from 'data/user';
import { SYSTEMS } from './constants';
import { TiWarningOutline } from 'react-icons/ti';
import { theme } from 'theme';

export default function CommonStepperDialog({
  connection,
  isCommonStepperDialogOpen,
  closeCommonStepperDialog,
  dialogSize,
  finalActionBtnProps,
  intialStep,
  stepperFlow,
  stepperFlowProps,
}) {
  const [activeStep, setActiveStep] = useState(intialStep ?? 0);
  const [isMetadataConfigured, setIsMetadataConfigured] = useState(false);
  const [isPicklistConfigured, setIsPicklistConfigured] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const queryClient = useQueryClient();
  const tenant = useTenantState();

  // Get the updated user-systems from the cache
  const userSystemEndpoint = `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`;
  const userSystems = queryClient.getQueryData([userSystemEndpoint, tenant?.tenant_id]);

  useEffect(() => {
    if (!isCommonStepperDialogOpen) {
      return;
    }
    // We need to skip metadata step if it's configured already
    queryClient.invalidateQueries([
      `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
      tenant?.tenant_id,
    ]);

    if (isMetadataConfigured) {
      setActiveStep(1);
      return;
    }

    setActiveStep(0);
  }, [
    isCommonStepperDialogOpen,
    isMetadataConfigured,
    queryClient,
    stepperFlow,
    tenant?.tenant_id,
  ]);

  useEffect(() => {
    const refreshSystemConfigState = async () => {
      // Extract the metadata
      const { metadata, picklist } = getSystemConfigurationAndPreferences(
        userSystems,
        connection?.user_system_id
      );

      setIsMetadataConfigured(Boolean(metadata));
      setIsPicklistConfigured(Boolean(picklist));
    };

    refreshSystemConfigState();
  }, [connection?.user_system_id, stepperFlow, userSystems]);

  const {
    btnAction: finalStepBtnAction,
    btnText: finalStepBtnText,
    btnProps: finalStepBtnProps,
  } = finalActionBtnProps;

  const stepperSteps = getStepperSteps(stepperFlow);

  const isLastStep = activeStep === stepperSteps.length - 1;

  const handlecloseStepperDialog = useCallback(() => {
    closeCommonStepperDialog();
    setIsPasswordValid(false);
    setActiveStep(0);
  }, [closeCommonStepperDialog]);

  const goToPreviousStep = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((state) => state - 1);
      setIsPasswordValid(false);
      return;
    }
  }, [activeStep]);

  const goToNextStep = useCallback(() => {
    if (activeStep === 0) {
      // When user clicks on the Continue button -> invalidate the user-system cache
      queryClient.invalidateQueries([
        `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
        tenant?.tenant_id,
      ]);

      if (isMetadataConfigured) {
        setActiveStep((state) => state + 1);
        return;
      }
      return;
    }

    if (!isLastStep) {
      setActiveStep((state) => state + 1);
    }

    if (isLastStep) {
      finalStepBtnAction();
      handlecloseStepperDialog();
      setActiveStep(0);
    }
    queryClient.invalidateQueries([
      `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`,
      tenant?.tenant_id,
    ]);
  }, [
    activeStep,
    isLastStep,
    queryClient,
    tenant?.tenant_id,
    isMetadataConfigured,
    finalStepBtnAction,
    handlecloseStepperDialog,
  ]);

  const primaryBtnText = setStepperDialogPrimaryBtnText(
    activeStep,
    stepperSteps.length,
    finalStepBtnText
  );

  const setPrimaryBtnProps = useCallback(
    (finalStepBtnProps) => {
      if (stepperFlow !== STEPPER_FLOWS.SYSTEM_CONFIGURATION.KEY && isLastStep) {
        return { ...finalStepBtnProps, disabled: !isPasswordValid };
      }
    },
    [isLastStep, isPasswordValid, stepperFlow]
  );

  const primaryBtnProps = setPrimaryBtnProps(finalStepBtnProps);

  return (
    <>
      <DialogWrapper
        title={stepperSteps.length === 1 ? stepperSteps[0] : null}
        isOpen={isCommonStepperDialogOpen}
        closeDialog={closeCommonStepperDialog}
        size={dialogSize}
        primaryBtnAction={goToNextStep}
        primaryBtnText={primaryBtnText}
        primaryBtnProps={primaryBtnProps}
        secondaryBtnAction={handlecloseStepperDialog}
        secondaryBtnText="close"
        otherBtnAction={activeStep > 0 ? goToPreviousStep : null}
        otherBtnText={activeStep > 0 ? 'Back' : ''}
        disableBackdropClick={true}
      >
        <Stepper activeStep={activeStep}>
          {stepperSteps.length > 1 &&
            stepperSteps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
        </Stepper>
        {getSteps(
          activeStep,
          stepperFlow,
          stepperFlowProps,
          connection,
          isMetadataConfigured,
          isPicklistConfigured
        )}
      </DialogWrapper>
    </>
  );
}

function getSteps(
  activeStep,
  stepperFlow,
  stepperFlowProps,
  connection,
  isMetadataConfigured,
  isPicklistConfigured
) {
  const navigateToSystemConfiguration = () => {
    navigateToSystems(connection?.tenant_id, connection?.user_system_id);
  };
  if (stepperFlow === STEPPER_FLOWS.SYSTEM_CONFIGURATION.KEY) {
    const { showPicklistValues, handleShowPicklistValues } = stepperFlowProps;
    switch (activeStep) {
      case 0:
        return (
          <MetadataConfigurationStep
            navigateToSystemConfiguration={navigateToSystemConfiguration}
            isMetadataConfigured={isMetadataConfigured}
            isRunQueryFlow={true}
          />
        );

      case 1:
        return (
          <PicklistConfigurationStep
            isPicklistConfigured={isPicklistConfigured}
            showPicklistValues={showPicklistValues}
            handleShowPicklistValues={handleShowPicklistValues}
            navigateToSystemConfiguration={navigateToSystemConfiguration}
            isRunQueryFlow={true}
          />
        );

      default:
        return null;
    }
  }
}

function MetadataConfigurationStep({
  navigateToSystemConfiguration,
  isMetadataConfigured,
  isRunQueryFlow,
}) {
  if (isMetadataConfigured) {
    return (
      <Stack height="100%" alignItems="center" m={1}>
        <GenericSuccessIllustration width="200px" height="200px" />
        <Typography color="textSecondary" align="center">
          The system metadata is configured properly. You're now ready to move on - please click the
          'Next' button to continue.
        </Typography>
      </Stack>
    );
  }
  if (!isMetadataConfigured && isRunQueryFlow) {
    return (
      <Stack height="100%" alignItems="center" m={1}>
        <NotFoundImage width="180px" height="200px" />
        <Typography color="textSecondary" align="center">
          If you want to view picklist values, System metadata configuration is required. Please
          configure the metadata to continue
          <Link
            sx={{ cursor: 'pointer', ml: 1 }}
            onClick={navigateToSystemConfiguration}
            fontWeight="600"
          >
            Configure Metadata
          </Link>
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack height="100%" alignItems="center" m={1}>
      <NotFoundImage width="180px" height="200px" />
      <Typography color="textSecondary" align="center">
        System metadata configuration is required before export. Please configure the metadata to
        continue
        <Link
          sx={{ cursor: 'pointer', ml: 1 }}
          onClick={navigateToSystemConfiguration}
          fontWeight="600"
        >
          Configure Metadata
        </Link>
      </Typography>
    </Stack>
  );
}

export function PicklistConfigurationStep({
  isPicklistConfigured,
  showPicklistValues,
  handleShowPicklistValues,
  navigateToSystemConfiguration,
  isRunQueryFlow,
}) {
  const [showPicklistAlert, setShowPicklistAlert] = useState(!isPicklistConfigured);

  const checkIfPicklistExist = useCallback(
    ({ currentTarget }) => {
      const status = currentTarget.dataset.showPicklist === 'true';

      if (status && !isPicklistConfigured) {
        setShowPicklistAlert(true);
        return;
      }

      setShowPicklistAlert(false);
    },
    [isPicklistConfigured]
  );
  if (isPicklistConfigured && isRunQueryFlow) {
    return (
      <Stack height="100%" alignItems="center" m={1}>
        <GenericSuccessIllustration width="200px" height="200px" />
        <Typography color="textSecondary" align="center">
          The system Picklist is configured properly. You're now ready to move on - please click the
          'Run' button to fetch data.
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack direction="column" mt={2}>
        {!isRunQueryFlow && (
          <>
            <DialogContentText sx={{ display: 'flex', flexDirection: 'row' }}>
              Do you want to view external codes?{' '}
              {showPicklistAlert && (
                <Tooltip
                  title={
                    'The system linked to this connection does not have a configured picklist. Without configuration, the data will lack external codes.'
                  }
                >
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 4,
                    }}
                  >
                    <TiWarningOutline style={{ color: theme.palette.warning.dark, fontSize: 16 }} />
                  </span>
                </Tooltip>
              )}
            </DialogContentText>
            <RadioGroup
              row
              aria-label="viewPicklistValues"
              value={showPicklistValues}
              onChange={handleShowPicklistValues}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes"
                data-show-picklist={true}
                onClick={checkIfPicklistExist}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
                data-show-picklist={false}
                onClick={checkIfPicklistExist}
              />
            </RadioGroup>
          </>
        )}
      </Stack>
      {showPicklistAlert && isRunQueryFlow && (
        <Stack height="100%" alignItems="center" m={1}>
          <NotFoundImage width="180px" height="200px" />
          <Typography color="textSecondary" align="center">
            The system linked to this connection doesn't have a configured picklist. Without
            configuration, the data will lack external codes.
            <Link
              sx={{ cursor: 'pointer', ml: 1 }}
              onClick={navigateToSystemConfiguration}
              fontWeight="600"
            >
              Configure Picklist
            </Link>
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
