import { ClickAwayListener, Popper, Stack } from '@mui/material';
import React, { useCallback } from 'react';

import { borders } from '../../mui5Theme';

export default function PopoverMenu({
  anchorEl,
  isOpen,
  close,
  children,
  sx,
  zIndex = 1,
  allowClickAway = true,
}) {
  const closePopoverMenu = useCallback(() => {
    if (allowClickAway) {
      close();
    }
  }, [allowClickAway, close]);

  return (
    <Popper
      open={isOpen}
      keepMounted={false}
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: false,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
      sx={(theme) => ({
        p: 2,
        top: '10px !important',
        border: borders[0],
        borderRadius: 0.5,
        maxHeight: theme.spacing(80),
        overflow: 'hidden',
        backgroundColor: theme.palette.common.white,
        zIndex: zIndex,
        ...sx,
      })}
    >
      <ClickAwayListener onClickAway={closePopoverMenu}>
        <Stack width="100%" height="100%">
          {children}
        </Stack>
      </ClickAwayListener>
    </Popper>
  );
}
