export const FETCH_CANCELLATION = {
  CANCEL_QUERY_TRIGGER: 'Cancel the query trigger',
  CANCEL_ALL_BATCHES: 'Cancel all batches',
  BY_DATAFLOW: 'Cancel triggered by Dataflow',
};

export const ROOT_LEVEL_GROUP_NAME = 'baseProperties';

export const PAGE_SIZE = 100;

export const FETCH_STATE = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
};

export const QUERY_STATE = {
  IDLE: 'IDLE',
  QUERYING: 'QUERYING',
  QUERY_SUCCESSFUL: 'QUERY_SUCCESSFUL',
  QUERY_FAILED: 'FAILED',
  RESET: 'RESET',
};

export const EDIT_RUN_STATUS = {
  STARTED: 'started',
  VALIDATION: 'validation',
  DATA_FILE_ERROR: 'data_file_error',
  GENERATION_REQUEST_REJECTED: 'generation_request_rejected',
  GENERATION_APPROVAL_PENDING: 'generation_approval_pending',
  GENERATION: 'generation',
  COMPLETED: 'completed',
  FAILED: 'failed',
  COMPLETED_WITH_ERRORS: 'completed_with_errors',
  ABORTED: 'aborted',
};

export const ERROR_TYPES = {
  INVALID_REQUEST_ERROR: 'internal_request_error',
  SYSTEM_NOT_CONFIGURED: 'user_system_not_configured',
  COMMON_ERRORS: 'COE',
  AUTHENTICATION_ERROR: 'authentication_error',
  VALIDATION_ERROR: 'validation_error',
};

export const LOG_TYPE = {
  QUERY_VIEW: 'QUERY_VIEW',
  REPORT_VIEW: 'REPORT_VIEW',
};

export const BOOKMARK_TYPE = {
  QUERY_VIEW: 'QUERY_VIEW',
  REPORT_VIEW: 'REPORT_VIEW',
};

export const JOB_STATE = {
  WAITING: 'waiting',
  STARTED: 'started',
  COMPLETED: 'completed',
  FAILED: 'failed',
  ABORTED: 'aborted',
  QUEUED: 'queued',
};

export const EXPORT_JOB_STEPS = [
  'Configure Metadata',
  'Configure Picklist',
  'Customize Sheet Name',
  'Password Protection',
];

export const SYSTEM_CONFIG_STEPS = ['Configure Metadata', 'Configure Picklist'];

export const SYSTEMS = {
  SF_EC: {
    KEY: 'SF_EC',
    LABEL: 'SuccessFactors Employee Central',
  },
  ODATA_SERVICE: {
    KEY: 'ODATA_SERVICE',
    LABEL: 'OData Service',
  },
  SF_EC_SHARED: {
    KEY: 'SF_EC_SHARED',
    LABEL: 'Demo SuccessFactors Employee Central',
  },
  ODATA_SERVICE_SHARED: {
    KEY: 'ODATA_SERVICE_SHARED',
    LABEL: 'Demo OData Service',
  },
};

export const STEPPER_FLOWS = {
  SYSTEM_CONFIGURATION: {
    KEY: 'SYSTEM_CONFIGURATION',
    STEPS: ['Configure Metadata', 'Configure Picklist'],
  },
};

export const COE_DOCUMENTATION =
  'https://help.sap.com/docs/SAP_SUCCESSFACTORS_PLATFORM/d599f15995d348a1b45ba5603e2aba9b/136931f720dd4b588ceccb422927f041.html?locale=en-US&q=COE';

export const LOOKUP_TYPES = {
  PICKLIST: 'picklist',
  FOUNDATION_OBJECT: 'foundation-object',
};

export const LOOKUP_TYPE_TABLE_COLUMNS = {
  [LOOKUP_TYPES.PICKLIST]: [
    { key: 'checkbox', label: '' },
    { key: 'label', label: 'Label' },
    { key: 'externalCode', label: 'External Code' },
    { key: 'optionId', label: 'Option ID' },
  ],
  [LOOKUP_TYPES.FOUNDATION_OBJECT]: [
    { key: 'checkbox', label: '' },
    { key: 'name', label: 'Name' },
    { key: 'externalCode', label: 'External Code' },
  ],
};

export const SHEET_NAMING_CONVENTION = {
  TECHNICAL: { value: 'entity_code', label: 'Technical (MDGen Compatible)' },
  USER_FRIENDLY: { value: 'entity_name', label: 'User friendly' },
};

export const DOM_IDS = {
  JOBS_PANEL: 'INTEGRTR__JOBS_PANEL',
  JOBS_HEADER: 'INTERGTR__JOBS_HEADER',
  TABS_PANEL: 'INTEGRTR__TABS_PANEL',
  REPORT_VIEW_DRAWER: 'INTEGRTR__REPORT_VIEW_DRAWER',
  COLLECTION_FIELDS: {
    NAME: 'INTEGRTR__COLLECTION_NAME_FIELD',
    DESCRIPTION: 'INTEGRTR__COLLECTION_DESC_FIELD',
  },
  EXPORT_PREFERENCES_HELP_BTN: 'INTEGRTR__EXPORT_PREFERENCES_HELP_BTN',
  QUERY_PREFERENCES_HELP_BTN: 'INTEGRTR__QUERY_PREFERENCES_HELP_BTN',
};
